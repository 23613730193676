$(() => {
  const writerElement = $('#bookPrintIndex').find('#writer_id');

  if (writerElement) {
    writerElement.select2({
      theme: 'default',
      minimumInputLength: 2,
      allowClear: true,
      ajax: {
        delay: 250,
        url: '/api/search/writer',
        method: 'get',
        dataType: 'json',
        data: (params) => {
          return {
            name: params.term,
          };
        },
        processResults: (data) => {
          const results = [];

          for (item of data) {
            results.push({
              id: item.id,
              text: item.name,
            });
          }

          return { results: results };
        },
      },
    });
  }

  const bookElement = $('#bookPrintIndex').find('#book_id');

  if (bookElement) {
    bookElement.select2({
      theme: 'default',
      minimumInputLength: 2,
      allowClear: true,
      ajax: {
        delay: 250,
        url: '/api/search/book',
        method: 'get',
        dataType: 'json',
        data: (params) => {
          return {
            title: params.term,
          };
        },
        processResults: (data) => {
          const results = [];

          for (item of data) {
            results.push({
              id: item.id,
              text: item.title,
            });
          }

          return { results: results };
        },
      },
    });
  }

  const publisherElement = $('#bookPrintIndex').find('#publisher_id');

  if (publisherElement) {
    publisherElement.select2({
      theme: 'default',
      minimumInputLength: 2,
      allowClear: true,
      ajax: {
        delay: 250,
        url: '/api/search/publisher',
        method: 'get',
        dataType: 'json',
        data: (params) => {
          return {
            title: params.term,
          };
        },
        processResults: (data) => {
          const results = [];

          for (item of data) {
            results.push({
              id: item.id,
              text: item.title,
            });
          }

          return { results: results };
        },
      },
    });
  }
});