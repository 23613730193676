document.querySelectorAll('[data-confirm]').forEach((element) => {
  const tagName = element.tagName.toLowerCase();
  const question = element.dataset.confirm;

  if (tagName === 'form') {
    element.addEventListener('submit', (event) => {
      if (!confirm(question)) {
        event.preventDefault();
        return false;
      }
      return true;
    });
  } else if (tagName === 'a' || tagName === 'button') {
    element.click = (event) => {
      return confirm(question);
    };
  }
});